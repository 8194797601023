import React from "react";
import { Global } from "@emotion/react";
import { Provider } from "react-redux";
import { Reset } from "styles";
import configureStore from "store";
import App, { urlLangCheck } from "App";

const Root: React.FC = () => {
  urlLangCheck();
  const store = configureStore();
  return (
    <>
      <Global styles={Reset} />
      <Provider store={store}>
        <App />
      </Provider>
    </>
  );
};

export default Root;
