import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Card, Descriptions, Rate, Col, Row } from "antd";

import Element from "api/element";
import Weapons from "api/weapons";

import utils from "utils";

const { common } = utils;

const { Item: DescItem } = Descriptions;

const BasicInfoComponent: React.FC<any> = ({ char, info, ...props }) => {
  const { t: ct } = useTranslation("Character");
  const { t } = useTranslation(`Character/${char}`);

  const flexSet = common.chkMobile() ? ["100%", "auto"] : ["320px", "auto"];

  const element = (Element as any)[info.element];
  const weapon = Weapons.common[info.weapon];
  return (
    <BasicInfo>
      <Row gutter={16}>
        <Col flex={flexSet[0]} style={{ marginBottom: "25px" }}>
          <Card cover={<img alt="example" src={info.avatar} />} actions={[<img src={element} key="element" />, <img src={weapon} key="weapon" />]}>
            <Rate disabled count={info.rarity} defaultValue={info.rarity} />
          </Card>
        </Col>
        <Col flex={flexSet[1]}>
          <Descriptions bordered column={1}>
            <DescItem label={ct("birthday")}>{t("birthday")}</DescItem>
            <DescItem label={ct("cityState")}>{t("cityState")}</DescItem>
            <DescItem label={ct("affiliation")}>{t("affiliation")}</DescItem>
            <DescItem label={ct("constellation")}>{t("constellation")}</DescItem>
            <DescItem label={ct("description")}>
              {(t("description", { returnObjects: true }) as string[]).map((item, index) => (
                <Desc key={index}>{item}</Desc>
              ))}
            </DescItem>
            <DescItem label={ct("voice")}>
              {(t("voice", { returnObjects: true }) as string[]).map((item, index) => (
                <Voice key={index}>{item}</Voice>
              ))}
            </DescItem>
          </Descriptions>
        </Col>
      </Row>
    </BasicInfo>
  );
};

export default BasicInfoComponent;

const BasicInfo = styled.div`
  .ant-card-actions li img {
    width: 100%;
    max-width: 64px;
  }
`;

const Desc = styled.p``;

const Voice = styled.div``;
