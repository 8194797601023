import React from "react";
import styled from "styles/theme";
import { useTranslation } from "react-i18next";

import { Tooltip } from "antd";

import ascenstion from "api/ascension";

const MaterialComponent: React.FC<any> = ({ material, rank }) => {
  const { t } = useTranslation("ascension");

  const agateGrade = [0, 1, 1, 2, 2, 3];
  const secondaryGrade = [0, 0, 1, 1, 2, 2];
  const materialRank = [
    [1, null, 3, 3, 20000],
    [3, 2, 10, 15, 40000],
    [6, 4, 20, 12, 60000],
    [3, 8, 30, 18, 80000],
    [6, 12, 45, 12, 100000],
    [6, 20, 60, 24, 120000]
  ];

  const ascensionImage = ascenstion as any;

  const ascensionInfo = [
    ascensionImage.agate[material[0]][agateGrade[rank]],
    ascensionImage.stone[material[1]],
    ascensionImage.local[material[2]],
    ascensionImage.second[material[3]][secondaryGrade[rank]],
    ascensionImage.mora
  ];

  const materialList = (material as string[]).reduce((acc: any[], _, index) => {
    acc.push([ascensionInfo[index]?.icon, ascensionInfo[index]?.title, materialRank[rank] && materialRank[rank][index]]);
    return acc;
  }, []);

  return (
    <Material>
      {materialList[0][1] &&
        materialList.map((item, index) => (
          <span key={index}>
            {item[2] && (
              <>
                <Tooltip placement="topLeft" title={t(item[1])}>
                  <img src={item[0]} alt={t(item[1])} /> x {item[2]}
                </Tooltip>
              </>
            )}
          </span>
        ))}
    </Material>
  );
};

export default MaterialComponent;

const Material = styled.div`
  & span {
    display: inline-block;
    margin-right: 10px;

    img {
      width: 42px;
      vertical-align: middle;
    }
  }
`;
