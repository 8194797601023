const req = require.context(".", true, /^(?!.\/index).*.ts$/);

const modules: { [key: string]: string } = {};

req.keys().forEach((key: string) => {
  const regex = /.\/(.*?).ts$/;
  const moduleName = (regex.test(key) && key.match(regex)![1]) as string;
  modules[moduleName] = req(key).default;
});

export default { "en-US": { ...modules } };
