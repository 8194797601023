import React from "react";
import { BrowserRouter, Routes, Route, Navigate, useParams } from "react-router-dom";
import cookie from "js-cookie";

import { ThemeProvider } from "@emotion/react";

import "locales";
import { locales, getDefaultLocale } from "locales/index";

import { theme, themeTypes } from "styles/theme";

import DefaultLayout from "layouts/DefaultLayout";
import ScrollTop from "utils/scrollTop";

import Home from "views/Home";
import Character from "views/Character";
import Release from "views/Character/release";

const getLocale = cookie.get("locale");

export const urlLangCheck = () => {
  const { location, history } = window;
  const { origin, pathname } = location;
  if (!pathname.includes(`${getLocale}`)) {
    history.replaceState(null, "", `${origin}/${getLocale}${pathname}`);
  }
};

const pathLang = (url: string[]) => {
  const result = url.reduce((acc: string[], cur) => {
    acc = [...acc];
    acc.push(cur);
    locales.forEach(item => {
      acc.push(`/${item}${cur}`);
    });
    return acc;
  }, []);

  return result;
};

const privateRules: RouteRules[] = [
  {
    exact: true,
    path: pathLang(["/", "/home"]),
    element: Home
  }
];

const publicRules: RouteRules[] = [
  {
    exact: true,
    path: pathLang(["/", "/home"]),
    element: Home
  },
  {
    exact: true,
    path: pathLang(["/characters/release", "/characters/unrelease"]),
    element: Release
  },
  {
    exact: true,
    path: pathLang(["/characters/release/:id", "/characters/unrelease/:id"]),
    element: Character
  }
];

const notLoginRules: RouteRules[] = [
  // {
  //   exact: true,
  //   path: pathLang(["/login"]),
  //   component: Login
  // },
  // {
  //   exact: true,
  //   path: pathLang(["/signup"]),
  //   component: SignUp
  // }
];

// const PrivateRoute: React.FC = () => {
//   return (
//     <Switch>
//       {privateRules.map((item, index) => (
//         <Route exact {...item} key={index} />
//       ))}
//       <Redirect to={`/${getLocale}`} />
//     </Switch>
//   );
// };

// const PublicRoute: React.FC = () => {
//   return (
//     <Switch>
//       {publicRules.map((item, index) => (
//         <Route exact {...item} key={index} />
//       ))}
//       <Redirect to={""} />
//     </Switch>
//   );
// }

const RouteSwitch: React.FC<RouteSwitch> = ({ isLoggedIn }) => {
  const params = useParams();

  console.log(params, "params");

  return (
    <Routes>
      <Route element={<DefaultLayout />}>
        <Route path="/:lang" element={<Home />} />
        <Route path="/:lang/characters/release" element={<Release />} />
        <Route path="/:lang/characters/unrelease" element={<Release />} />
        <Route path="/:lang/characters/release/:id" element={<Character />} />
        <Route path="*" element={<Navigate to={`/${getDefaultLocale()}`} />} />
      </Route>

      {/* {isLoggedIn && privateRules.map((item, index) => <Route {...item} key={index} />)}
      {!isLoggedIn && notLoginRules.map((item, index) => <Route {...item} key={index} />)}
      {publicRules.map((item, index) => (
        <Route {...item} key={index} />
      ))} */}
    </Routes>
  );
};

const Root = () => {
  // const { theme, isLoggedIn } = clientData;

  const themeValue = "light";
  const isLoggedIn = true;

  document.body.id = themeValue;

  // if (error) return <div>{error.message}</div>;
  // if (clientLoading) return <div>Root Loading</div>;

  // console.log(theme[themeValue as themeTypes] as any, "theme[themeValue as themeTypes] as any");

  // const CurrentLayout = DefaultLayout;

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme[themeValue as themeTypes] as any}>
        <ScrollTop />
        <RouteSwitch isLoggedIn={isLoggedIn} />
      </ThemeProvider>
    </BrowserRouter>
  );
};

export { publicRules, notLoginRules };
export default Root;
