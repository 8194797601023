let intro = 0;

const chkMobile = () => {
  let result;
  const userAgent = window.navigator.userAgent;
  const mobile = userAgent.indexOf("Android") > -1 || userAgent.indexOf("iPhone") > -1 || userAgent.indexOf("iPad") > -1;
  if (intro === 0) {
    intro++;
    result = window.innerWidth > 768 && !mobile ? false : true;
    // result = window.innerWidth < 769 && mobile ? true : false;
  } else {
    result = window.innerWidth > 768 ? false : true;
    // result = window.innerWidth < 769 ? true : false;
  }
  return result;
};

export default { chkMobile };
