import pyro from "assets/elements/pyro.png";
import cryo from "assets/elements/cryo.png";
import hydro from "assets/elements/hydro.png";
import electro from "assets/elements/electro.png";
import anemo from "assets/elements/anemo.png";
import geo from "assets/elements/geo.png";
import dendro from "assets/elements/dendro.png";

export default {
  pyro,
  cryo,
  hydro,
  electro,
  anemo,
  geo,
  dendro
};
