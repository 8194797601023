const req = require.context("../../assets/character", true, /^(?!.\/index).*.png$/);

const modules: { [key: string]: string } = {};

req
  .keys()
  .filter(item => item.indexOf("avatar") > 0)
  .forEach((key: string) => {
    const regex = /.\/(.*?).png$/;
    const moduleName = ((regex.test(key) && key.match(regex)![1]) as string).split("/")[0];
    modules[moduleName] = req(key).default;
  });

export default {
  ...modules
};
