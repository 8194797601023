export default {
  home: "home",
  character: "character",
  release: "release",
  unrelease: "unrelease",
  birthday: "Birthday",
  constellation: "Constellation",
  cityState: "City State",
  affiliation: "Affiliation",
  voice: "Voice",
  description: "Description",
  ascensionStatus: "Level Ascension Status",
  level: "Level",
  baseHP: "Base HP",
  baseATK: "Base ATK",
  baseDEF: "Base DEF",
  atk: "ATK%",
  critDmg: "CRIT DMG%",
  critRate: "CRIT Rate%",
  ascension: "Ascension",
  combatTalents: "Combat Talents",
  passiveTalents: "Passive Talents",
  talentsLevel: "Lv. ",
  talentStatus: "Talent Ascension Status",
  characterPhase: "Level Phase"
};
