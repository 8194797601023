const req = require.context("../../assets/character", true, /^(?!.\/index).*.png$/);

const modules: { [key: string]: any } = {};

let constellation: string[] = [];
let combat: string[] = [];
let passive: string[] = [];

req.keys().forEach((key: string) => {
  const regex = /.\/(.*?).png$/;
  const avatar = key.indexOf("avatar") > 0 && req(key).default;
  key.indexOf("constellation") > 0 && constellation.push(req(key).default);
  key.indexOf("combat") > 0 && combat.push(req(key).default);
  key.indexOf("passive") > 0 && passive.push(req(key).default);
  const moduleName = ((regex.test(key) && key.match(regex)![1]) as string).split("/");

  if (avatar) {
    modules[moduleName[0]] = { avatar };
  }

  if (constellation.length > 5) {
    modules[moduleName[0]] = { ...modules[moduleName[0]], constellation };
    constellation = [];
  }

  if (combat.length > 2) {
    modules[moduleName[0]] = { ...modules[moduleName[0]], combat };
    combat = [];
  }

  if (passive.length > 2) {
    modules[moduleName[0]] = { ...modules[moduleName[0]], passive };
    passive = [];
  }
});

export default modules;
