import produce from "immer";
import { createAction, handleActions } from "redux-actions";

// ActionsTypes
export const actionTypes = {
  INCREMENT: "INCREMENT",
  DECREMENT: "DECREMENT",
  RESET: "RESET"
};

// Actions
export const incrementCount = createAction(actionTypes.INCREMENT);
export const decrementCount = createAction(actionTypes.DECREMENT);
export const resetCount = createAction(actionTypes.RESET);

// InitialState
const exampleInitialState = {
  routing: "dkdkdkdkdk",
  count: 0,
  isLogined: true
};

// Reducers
export const reducer = handleActions(
  {
    [actionTypes.INCREMENT]: (state, action) =>
      produce(state, draftState => {
        draftState.count += 1;
      }),
    [actionTypes.DECREMENT]: (state, action) =>
      produce(state, draftState => {
        draftState.count -= 1;
      }),
    [actionTypes.RESET]: (state, action) =>
      produce(state, draftState => {
        draftState.count = 0;
      })
  },
  exampleInitialState
);
