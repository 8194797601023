export default [
  {
    name: "amber",
    rarity: 4,
    element: "pyro",
    weapon: "bow",
    area: "mond",
    release: true,
    releaseDate: new Date("2020.09.28").getTime(),
    reShowDate: null
  },
  {
    name: "hutao",
    rarity: 5,
    element: "pyro",
    weapon: "polearm",
    area: "liyue",
    release: true,
    releaseDate: new Date("2021.03.02").getTime(),
    reShowDate: null
  }
];
