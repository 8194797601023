import crown_pic from "assets/inventory/talents/crown.png";

const req = require.context("../assets/inventory/talents", true, /^(?!.\/index).*.png$/);

const book: { [key: string]: { [key: string]: string }[] } = {};
const boss: { [key: string]: { [key: string]: string }[] } = {};
const crown = { title: "crown", icon: crown_pic };

req.keys().forEach((key: string) => {
  const regex = /.\/(.*?).png$/;
  const regex_underbar = /_[abcdefghijk]_/;
  const book_pic = key.indexOf("book_") > 0 && req(key).default;
  const boss_pic = key.indexOf("boss_") > 0 && req(key).default;

  const moduleName = ((regex.test(key) && key.match(regex)![1]) as string).split("_");

  if (book_pic) {
    const title = key.match(regex)![1].replace(regex_underbar, "_").split("book_")[1];
    if (typeof book[moduleName[1]] === "object") {
      book[moduleName[1]].push({ title, icon: book_pic });
    } else {
      book[moduleName[1]] = [{ title, icon: book_pic }];
    }
  }

  if (boss_pic) {
    const title = key.match(regex)![1].split("boss_")[1];
    if (typeof boss[moduleName[1]] === "object") {
      boss[moduleName[1]].push({ title, icon: boss_pic });
    } else {
      boss[moduleName[1]] = [{ title, icon: boss_pic }];
    }
  }
});

export default { book, boss, crown };
