export default {
  agate_agnidus_sliver: "Agnidus Agate Sliver",
  agate_agnidus_fragment: "Agnidus Agate Fragment",
  agate_agnidus_chunk: "Agnidus Agate Chunk",
  agate_agnidus_gemstone: "Agnidus Agate Gemstone",
  juvenile_jade: "Juvenile Jade",
  silk_flower: "Silk Flower",
  nectar_whopperflower: "Whopperflower Nectar",
  nectar_shimmering: "Shimmering Nectar",
  nectar_energy: "Energy Nectar",
  mora: "Mora"
};
