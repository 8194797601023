import React, { useState, useRef } from "react";
import styled from "styles/theme";
import { useTranslation } from "react-i18next";

import { Card, Col, Row, Table, Divider, Descriptions, Modal } from "antd";
import { YoutubeFilled } from "@ant-design/icons";
import { getDefaultLocale } from "locales";
import utils from "utils";

const { common } = utils;

const { Item } = Descriptions;

const CombatTalentComponent: React.FC<any> = ({ char, combatTalents }) => {
  const { t: ct } = useTranslation("Character");
  const { t } = useTranslation(`Character/${char}`);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const modal = useRef(null);

  const showModal = (title: string, url: string) => {
    setIsModalVisible(true);

    setTimeout(() => {
      if (modal && modal.current) {
        const modalCurrent = (modal!.current! as HTMLDivElement).parentElement!.parentElement!;
        const titleEl = modalCurrent.querySelector(".ant-modal-title");
        const iframeEl = modalCurrent.querySelector(".ant-modal-body iframe");

        if (titleEl && iframeEl) {
          (titleEl as HTMLDivElement).innerHTML = title;
          (iframeEl as HTMLIFrameElement).src = `${url}?autoplay=1`;
        }
      }
    }, 100);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    const iframe = document.querySelector(".ant-modal-body iframe");
    (iframe as HTMLIFrameElement).src = "";
  };

  const flexSet = common.chkMobile() ? ["100%", "auto"] : ["100px", "auto"];

  const attackColumns: any = [
    {
      title: null,
      dataIndex: "leftTitle",
      key: "leftTitle",
      fixed: "left",
      width: "115px",
      render: (_: any, Item: any) => <span dangerouslySetInnerHTML={{ __html: Item.leftTitle }} />
    }
  ];

  for (let i = 1; i < 16; i++) {
    attackColumns.push({
      title: ct("talentsLevel") + i,
      dataIndex: `level${i}`,
      key: `level${i}`,
      fixed: i === 15 ? "right" : undefined,
      width: "85px",
      render: (_: any, Item: any) => <span dangerouslySetInnerHTML={{ __html: Item[`level${i}`] }} />
    });
  }

  combatTalents.forEach((item: any) => {
    item["levelInfo"] = [];
    item.levelDetail.leftTitle.forEach((item2: any, i: number) => {
      item["levelInfo"].push({
        key: i + 1,
        leftTitle: t(item2),
        level: item.levelDetail.level[i].value
      });

      const unit = t(item.levelDetail.level[i].unit);

      const length = item["levelInfo"].length;

      item["levelInfo"][length - 1].level.forEach((item3: any, k: number) => {
        const levelValue = unit ? (unit.indexOf("%%") > -1 ? unit.replace("%%", item3) : item3 + unit) : item3;
        item["levelInfo"][length - 1][`level${k + 1}`] = levelValue;
      });
    });
  });

  return (
    <CombatTalent>
      {combatTalents.map((item: any, index: number) => (
        <Card type="inner" key={index}>
          <Row gutter={16}>
            <Col flex={flexSet[0]} className={"thum-video"}>
              <TalentIcon>
                <img alt={`${t(item.title)} Icon`} src={item.icon} />
              </TalentIcon>
              {item.videoUrl && (
                <VideoIcon>
                  <YoutubeFilled
                    style={{ fontSize: "50px" }}
                    onClick={() => showModal(t(item.title), item.videoUrl[getDefaultLocale()] ? item.videoUrl[getDefaultLocale()] : item.videoUrl["en-US"])}
                  />
                </VideoIcon>
              )}
            </Col>
            <Col flex={flexSet[1]} style={{ flex: 1 }}>
              <Title>{t(item.title)}</Title>
              {item.description && (
                <>
                  {typeof item.description === "string" ? (
                    <p dangerouslySetInnerHTML={{ __html: t(item.description) }} />
                  ) : (
                    item.description.map((item: any, index: number) => <p key={index}>- {t(item)}</p>)
                  )}
                </>
              )}
              {item.detail && (
                <Descriptions layout="vertical" bordered column={1}>
                  {item.detail.map((item: any, index: number) => (
                    <Item label={t(item.title)} key={index}>
                      {typeof item.text === "string" ? (
                        <p dangerouslySetInnerHTML={{ __html: t(item.text) }} />
                      ) : (
                        item.text.map((item: any, index: number) => <ListItem key={index} dangerouslySetInnerHTML={{ __html: t(item) }} />)
                      )}
                    </Item>
                  ))}
                </Descriptions>
              )}
            </Col>
          </Row>
          {item.skillEnd && <SkillEnd key={index} dangerouslySetInnerHTML={{ __html: t(item.skillEnd) }} />}
          {item.quote && (
            <>
              <Divider />
              <QuoteDesc key={index} dangerouslySetInnerHTML={{ __html: t(item.quote) }} />
              <Divider />
            </>
          )}
          <Table columns={attackColumns} scroll={{ x: "100%" }} dataSource={item.levelInfo} size={"small"} pagination={false} bordered />
        </Card>
      ))}

      <Modal title={"_"} width={"90%"} visible={isModalVisible} footer={null} onCancel={handleCancel}>
        <VideoArea ref={modal}>
          <iframe
            src={""}
            title="Genshin Ju-rie Games"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </VideoArea>
      </Modal>
    </CombatTalent>
  );
};

export default CombatTalentComponent;

const CombatTalent = styled.div`
  .thum-video {
    @media only screen and (max-width: 769px) {
      margin-bottom: 10px;
    }
  }
`;

const TalentIcon = styled(Card)`
  display: inline-block;
  max-width: 100px;
  vertical-align: middle;

  .ant-card-body {
    padding: 5px;
    background: #ddd;
    text-align: center;

    img {
      width: 100%;
      max-width: 100px;
    }
  }
`;

const VideoIcon = styled.div`
  display: inline-block;
  padding: 15px 0;
  width: 100px;
  text-align: center;
  vertical-align: middle;
`;

const Title = styled.h2`
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
`;

const ListItem = styled.p`
  position: relative;
  padding-left: 10px;

  &::before {
    content: "-";
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const SkillEnd = styled.p`
  margin-top: 24px;
  padding: 0 10px;
  width: 100%;
`;

const QuoteDesc = styled.p`
  padding: 0 10px;
`;

const VideoArea = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
