import React from "react";
import styled from "styles/theme";
import { useTranslation } from "react-i18next";

import { Tooltip } from "antd";

import ascenstion from "api/ascension";
import talent from "api/talent";

const MaterialComponent: React.FC<any> = ({ material, rank }) => {
  const { t } = useTranslation("ascension");

  const materialGrade = [0, 0, 1, 1, 1, 1, 2, 2, 2, 2];
  const materialRank = [
    [null, null, null, null, null],
    [3, 6, null, null, 12500],
    [2, 3, null, null, 17500],
    [4, 4, null, null, 25000],
    [6, 6, null, null, 30000],
    [9, 9, null, null, 37500],
    [4, 4, 1, null, 120000],
    [6, 6, 1, null, 260000],
    [12, 9, 2, null, 450000],
    [16, 12, 2, 1, 700000]
  ];

  const ascensionImage = ascenstion as any;
  const talentImage = talent as any;

  const ascensionInfo = [
    talentImage.book[material[0]][materialGrade[rank]],
    ascensionImage.second[material[1]][materialGrade[rank]],
    rank > 5 && talentImage.boss[material[2].split("_")[0]].filter((item: any) => item.title === material[2])[0],
    rank === 9 && talentImage.crown,
    ascensionImage.mora
  ];

  const materialList = (material as string[])
    .reduce((acc: any[], _, index) => {
      acc.push([ascensionInfo[index]?.icon, ascensionInfo[index]?.title, materialRank[rank] && materialRank[rank][index]]);
      return acc;
    }, [])
    .filter(item => item[0]);

  return (
    <Material>
      {materialList[0][1] &&
        materialList.map((item, index) => (
          <span key={index}>
            {item[2] && (
              <>
                <Tooltip placement="topLeft" title={t(item[1])}>
                  <img src={item[0]} alt={t(item[1])} /> x {item[2]}
                </Tooltip>
              </>
            )}
          </span>
        ))}
    </Material>
  );
};

export default MaterialComponent;

const Material = styled.div`
  & span {
    display: inline-block;
    margin-right: 10px;

    img {
      width: 42px;
      vertical-align: middle;
    }
  }
`;
