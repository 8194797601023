import infoPic from "api/characters/infoPic";

const characterName = "hutao";

infoPic[characterName];

const hutao = {
  avatar: infoPic[characterName].avatar,
  rarity: 5,
  element: "pyro",
  weapon: "polearm",
  area: "liyue",
  release: true,
  ascensionMaterial: ["agnidus", "juvenile_jade", "silk_flower", "nectar", "mora"],
  talentMaterial: ["diligence", "nectar", "tartaglia_shard", "crown", "mora"],
  uniqueStatus: "critDmg",
  status: {
    key: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
    level: ["1", "20", "20+", "40", "40+", "50", "50+", "60", "60+", "70", "70+", "80", "80+", "90"],
    baseHP: [1211, 3141, 4179, 6253, 6990, 8042, 9026, 10089, 10826, 11899, 12637, 13721, 14459, 15552],
    baseATK: [8, 21, 29, 43, 48, 55, 62, 69, 74, 81, 86, 94, 99, 106],
    baseDEF: [68, 177, 235, 352, 394, 453, 508, 568, 610, 670, 712, 773, 815, 876],
    uniqueStatus: [50, 50, 50, 50, 59.6, 59.6, 69.2, 69.2, 69.2, 69.2, 78.8, 78.8, 88.4, 88.4],
    ascension: [null, 0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, undefined]
  },
  talents: {
    key: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    charLevel: [1, 2, 3, 3, 4, 4, 5, 5, 6, 6],
    ascension: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  },
  combatTalents: [
    {
      icon: infoPic[characterName].combat[0],
      title: "normalAttack",
      // videoUrl: {
      //   "en-US": "https://www.youtube.com/embed/xDxsppKte_0",
      //   "ko-KR": "https://www.youtube.com/embed/8XN2BCzapZc"
      // },
      detail: [
        {
          title: "normalAttackTitle",
          text: "normalAttackText"
        },
        {
          title: "chargedAttackTitle",
          text: "chargedAttackText"
        },
        {
          title: "plungingAttackTitle",
          text: "plungingAttackText"
        }
      ],
      levelDetail: {
        leftTitle: ["hitDmg1", "hitDmg2", "hitDmg3", "hitDmg4", "hitDmg5", "hitDmg6", "chargedAttackDmg", "chargedStaminaCost", "plungeDmg", "lowHighPlungeDmg"],
        level: [
          {
            value: ["46.89%", "50.08%", "53.28%", "57.54%", "60.74%", "64.47%", "69.26%", "74.06%", "78.85%", "83.65%", "88.44%", "93.24%", "98.04%", "102.83%", "107.63%"]
          },
          {
            value: ["48.25%", "51.54%", "54.83%", "59.22%", "62.51%", "66.35%", "71.28%", "76.22%", "81.15%", "86.09%", "91.02%", "95.96%", "100.89%", "105.83%", "110.76%"]
          },
          {
            value: ["61.05%", "65.21%", "69.38%", "74.93%", "79.09%", "83.94%", "90.19%", "96.43%", "102.68%", "108.92%", "115.16%", "121.41%", "127.65%", "133.89%", "140.14%"]
          },
          {
            value: ["65.64%", "70.12%", "74.59%", "80.56%", "85.03%", "90.26%", "96.97%", "103.68%", "110.4%", "117.11%", "123.82%", "130.54%", "137.25%", "143.96%", "150.68%"]
          },
          {
            value: [
              "33.27% + 35.2%",
              "35.54% + 37.6%",
              "37.81% + 40%",
              "40.84% + 43.2%",
              "43.1% + 45.6%",
              "45.75% + 48.4%",
              "49.15% + 52%",
              "52.56% + 55.6%",
              "55.96% + 59.2%",
              "59.36 % + 62.8%",
              "62.77 % + 66.4%",
              "66.17 % + 70%",
              "69.57 % + 73.6%",
              "72.98 % + 77.2%",
              "76.38 % + 80.8%"
            ]
          },
          {
            value: [
              "85.96%",
              "91.82%",
              "97.68%",
              "105.49%",
              "111.36%",
              "118.19%",
              "126.98%",
              "135.78%",
              "144.57%",
              "153.36%",
              "162.15%",
              "170.94%",
              "179.73%",
              "188.52%",
              "197.31%"
            ]
          },
          {
            value: [
              "135.96%",
              "145.23%",
              "154.5%",
              "166.86%",
              "176.13%",
              "186.95%",
              "200.85%",
              "214.76%",
              "228.66%",
              "242.57%",
              "256.47%",
              "270.38%",
              "284.28%",
              "298.19%",
              "312.09%"
            ]
          },
          {
            value: [25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25]
          },
          {
            value: ["65.42%", "69.88%", "74.34%", "80.29%", "84.75%", "89.95%", "96.64%", "103.33%", "110.02%", "116.71%", "123.4%", "130.1%", "136.79%", "143.48%", "150.17%"]
          },
          {
            value: [
              "130.81% / 163.39%",
              "139.73% / 174.53%",
              "148.65% / 185.67%",
              "160.54% / 200.52%",
              "169.46% / 211.66%",
              "179.86% / 224.66%",
              "193.24% / 241.37%",
              "206.62% / 258.08%",
              "220% / 274.79%",
              "233.38% / 291.5%",
              "246.76% / 308.21%",
              "260.13% / 324.92%",
              "273.51% / 341.63%",
              "286.89% / 358.34%",
              "300.27% / 375.05%"
            ]
          }
        ]
      }
    },
    {
      icon: infoPic[characterName].combat[1],
      title: "eSkillTitle",
      // videoUrl: {
      //   "en-US": "https://www.youtube.com/embed/xDxsppKte_0",
      //   "ko-KR": "https://www.youtube.com/embed/8XN2BCzapZc"
      // },
      description: "eSkillDesc",
      detail: [
        {
          title: "eSkillEffect1",
          text: ["eSkillEffect1Text1", "eSkillEffect1Text2", "eSkillEffect1Text3", "eSkillEffect1Text4"]
        },
        {
          title: "eSkillEffect2",
          text: ["eSkillEffect2Text1", "eSkillEffect2Text2"]
        }
      ],
      skillEnd: "eSkillEffectEnd",
      quote: "eSkillQuote",
      levelDetail: {
        leftTitle: ["eSkillEffectTitle1", "eSkillEffectTitle2", "eSkillEffectTitle3", "eSkillEffectTitle4", "skillDuration", "skillCD"],
        level: [
          {
            value: ["30%", "30%", "30%", "30%", "30%", "30%", "30%", "30%", "30%", "30%", "30%", "30%", "30%", "30%", "30%"],
            unit: "currentHP"
          },
          {
            value: ["3.84%", "4.07%", "4.3%", "4.6%", "4.83%", "5.06%", "5.36%", "5.66%", "5.96%", "6.26%", "6.56%", "6.85%", "7.15%", "7.45%", "7.75%"],
            unit: "maxHP"
          },
          {
            value: ["64%", "68.8%", "73.6%", "80%", "84.8%", "89.6%", "96%", "102.4%", "108.8%", "115.2%", "121.6%", "128%", "136%", "144%", "152%"]
          },
          {
            value: [8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8],
            unit: "second"
          },
          {
            value: [9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9],
            unit: "second"
          },
          {
            value: [16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16],
            unit: "second"
          }
        ]
      }
    },
    {
      icon: infoPic[characterName].combat[2],
      title: "qSkillTitle",
      description: "qSkillDesc",
      quote: "qSkillQuote",
      levelDetail: {
        leftTitle: ["qSkillEffectTitle1", "qSkillEffectTitle2", "qSkillEffectTitle3", "qSkillEffectTitle4", "skillCD", "skillEnergyCost"],
        level: [
          {
            value: [
              "303.27%",
              "321.43%",
              "339.59%",
              "363.2%",
              "381.36%",
              "399.52%",
              "423.13%",
              "446.74%",
              "470.34%",
              "493.95%",
              "517.56%",
              "541.17%",
              "564.78%",
              "588.38%",
              "611.99%"
            ]
          },
          {
            value: ["379.09%", "401.79%", "424.49%", "454%", "476.7%", "499.4%", "528.91%", "558.42%", "587.93%", "617.44%", "646.95%", "676.46%", "705.97%", "735.48%", "764.99%"]
          },
          {
            value: ["6.26%", "6.64%", "7.01%", "7.5%", "7.88%", "8.25%", "8.74%", "9.23%", "9.71%", "10.2%", "10.69%", "11.18%", "11.66%", "12.15%", "12.64%"],
            unit: "maxHP"
          },
          {
            value: ["8.35%", "8.85%", "9.35%", "10%", "10.5%", "11%", "11.65%", "12.3%", "12.95%", "13.6%", "14.25%", "14.9%", "15.55%", "16.2%", "16.85%"],
            unit: "maxHP"
          },
          {
            value: [15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15],
            unit: "second"
          },
          {
            value: [60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60]
          }
        ]
      }
    }
  ],
  passiveTalents: [
    {
      icon: infoPic[characterName].passive[0],
      title: "passiveTitle1",
      description: "passiveDesc1",
      unLock: "passiveUnlock1"
    },
    {
      icon: infoPic[characterName].passive[1],
      title: "passiveTitle2",
      description: "passiveDesc2",
      unLock: "passiveUnlock2"
    },
    {
      icon: infoPic[characterName].passive[2],
      title: "passiveTitle3",
      description: "passiveDesc3"
    }
  ],
  constellation: [
    {
      icon: infoPic[characterName].constellation[0],
      title: "constellTitle1",
      description: "constellDesc1"
    },
    {
      icon: infoPic[characterName].constellation[1],
      title: "constellTitle2",
      description: "constellDesc2"
    },
    {
      icon: infoPic[characterName].constellation[2],
      title: "constellTitle3",
      description: "constellDesc3"
    },
    {
      icon: infoPic[characterName].constellation[3],
      title: "constellTitle4",
      description: "constellDesc4"
    },
    {
      icon: infoPic[characterName].constellation[4],
      title: "constellTitle5",
      description: "constellDesc5"
    },
    {
      icon: infoPic[characterName].constellation[5],
      title: "constellTitle6",
      description: "constellDesc6"
    }
  ]
};

export default hutao;
