import { locales } from "locales";
import release from "api/characters/release";

const route = ["/", "/home", "/characters/release", "/characters/unrelease", "/characters/release/:id", "/characters/unrelease/:id"];

const releaseChar = release.reduce((acc: string[], cur) => {
  acc.push(cur.name);
  return acc;
}, []);

const subRoute: any = {
  "/characters/release/": releaseChar
};

export const routeSnap = route.reduce((acc: string[], cur) => {
  locales.forEach(item => {
    let url = cur;
    if (cur.indexOf(":id") > 0) {
      url = cur.split(":id")[0];

      subRoute[url]?.forEach((_id: string) => acc.push(`/${item}${url}${_id}`));
    } else {
      acc.push(`/${item}${cur}`);
    }
  });

  cur.indexOf(":id") < 0 && acc.push(cur);
  return acc;
}, []);
