export default {
  name: "Hu Tao",
  nickName: "Fragrance in Thaw",
  birthday: "15 July",
  cityState: "Liyue Harbor",
  affiliation: "Wangsheng Funeral Parlor",
  constellation: "Papilio Charontis",
  description: [
    "Hu Tao is the 77th Director of the Wangsheng Funeral Parlor, a person vital to managing Liyue's funerary affairs.",
    "She does her utmost to flawlessly carry out a person's last rites and preserve the world's balance of yin and yang.",
    "Aside from this, she is also a talented poet whose many `masterpieces` have passed around Liyue's populace by word of mouth."
  ],
  voice: ["Brianna Knickerbocker", "김하루", "陶典", "高橋李依"],
  normalAttack: "Normal Attack: Secret Spear of Wangsheng",
  normalAttackTitle: "Normal Attack",
  normalAttackText: "Performs up to six consecutive spear strikes.",
  chargedAttackTitle: "Charged Attack",
  chargedAttackText: "Consumes a certain amount of Stamina to lunge forward, dealing damage to opponents along the way.",
  plungingAttackTitle: "Plunging Attack",
  plungingAttackText: "Plunges from mid-air to strike the ground below, damaging opponents along the path and dealing AoE DMG upon impact.",
  hitDmg1: "1-Hit DMG",
  hitDmg2: "2-Hit DMG",
  hitDmg3: "3-Hit DMG",
  hitDmg4: "4-Hit DMG",
  hitDmg5: "5-Hit DMG",
  hitDmg6: "6-Hit DMG",
  chargedAttackDmg: "Charged Attack",
  chargedStaminaCost: "Charged Attack Stamina Cost",
  plungeDmg: "Plunge DMG",
  lowHighPlungeDmg: "Low/High Plunge DMG",
  eSkillTitle: "Guide to Afterlife",
  eSkillDesc:
    "Only an unwavering flame can cleanse the impurities of this world. <br />Hu Tao consumes a set portion of her HP to knock the surrounding enemies back and enter the Paramita Papilio state.",
  eSkillEffect1: "Paramita Papilio",
  eSkillEffect1Text1: "Increases Hu Tao's ATK based on her Max HP at the time of entering this state. ATK Bonus gained this way cannot exceed 400% of Hu Tao's Base ATK.",
  eSkillEffect1Text2: "Converts attack DMG to <strong class='pyro'>Pyro DMG</strong>, which cannot be overridden by any other elemental infusion.",
  eSkillEffect1Text3: "Charged Attacks apply the Blood Blossom effect to the enemies hit.",
  eSkillEffect1Text4: "Increases Hu Tao's resistance to interruption.",
  eSkillEffect2: "Plunging Attack",
  eSkillEffect2Text1: "<strong class='pyro'>Pyro DMG</strong> every 4s. This DMG is considered Elemental Skill DMG.",
  eSkillEffect2Text2: "Each enemy can be affected by only one Blood Blossom effect at a time, and its duration may only be refreshed by Hu Tao herself.",
  eSkillEffectEnd: "Paramita Papilio ends when its duration is over, or Hu Tao has left the battlefield or fallen.",
  eSkillQuote:
    "Hu Tao's Secret Spear technique is based on several rules, the first of which is: [The spear opens the path to the afterlife, and the butterflies bridge this world and the next.]",
  eSkillEffectTitle1: "Activation Cost",
  eSkillEffectTitle2: "ATK Increase",
  eSkillEffectTitle3: "Blood Blossom DMG",
  eSkillEffectTitle4: "Blood Blossom Duration",
  qSkillTitle: "Spirit Soother",
  qSkillDesc:
    "<strong class='pyro'>Pyro DMG</strong> in a large AoE. <br/>Upon striking the enemy, regenerates a percentage of Hu Tao's Max HP. This effect can be triggered up to 5 times, based on the number of enemies hit. <br/>If Hu Tao's HP is below or equal to 50% when the enemy is hit, both the DMG and HP Regeneration are increased.",
  qSkillQuote:
    "Supernatural activity by those who have already left this world is a source of great anxiety for the Wangsheng Funeral Parlor. In such cases, cremation always lets Hu Tao find peace of mind. The more anxious she feels, the stronger the flame.",
  qSkillEffectTitle1: "Skill DMG",
  qSkillEffectTitle2: "Low HP Skill DMG",
  qSkillEffectTitle3: "Skill HP Regeneration",
  qSkillEffectTitle4: "Low HP Skill Regeneration",
  skillDuration: "Duration",
  skillCD: "CD",
  skillEnergyCost: "Energy Cost",
  currentHP: "%% Current HP",
  maxHP: "%% <br />Max HP",
  second: "s",
  passiveTitle1: "Flutter By",
  passiveDesc1:
    "When a Paramita Papilio state activated by Guide to Afterlife ends, all allies in the party (excluding Hu Tao herself) will have their CRIT Rate increased by 12% for 8s.",
  passiveUnlock1: "Unlock at Character Ascension Phase 1",
  passiveTitle2: "Sanguine Rouge",
  passiveDesc2: "When Hu Tao's HP is equal to or less than 50%, her <strong class='pyro'>Pyro DMG Bonus</strong> is increased by 33%.",
  passiveUnlock2: "Unlock at Character Ascension Phase 4",
  passiveTitle3: "The More the Merrier",
  passiveDesc3: "When Hu Tao cooks a dish perfectly, she has a 18% chance to receive an additional「Suspicious」 dish of the same type.",
  constellTitle1: "Crimson Bouquet",
  constellDesc1: "Guide to Afterlife, Hu Tao's Charge Attacks do not consume Stamina.",
  constellTitle2: "Ominous Rainfall",
  constellDesc2:
    "It increases the Blood Blossom DMG by the amount equal to 10% of Hu Tao's max HP when the effect is applied. <br />Additionally, Spirit Soother also applies the Blood Blossom effect.",
  constellTitle3: "Lingering Carmine",
  constellDesc3: "It increases the level of Guide to Afterlife talent by 3 levels.",
  constellTitle4: "Garden of Eternal Rest",
  constellDesc4:
    "Upon defeating an enemy affected by a Blood Blossom that Hu Tao applied herself, all nearby allies in the party (excluding Hu Tao herself) will have their CRIT Rate increased by 12% for 15s.",
  constellTitle5: "Floral Incense",
  constellDesc5: "It increases the level of Spirit Soother talent by 3 levels.",
  constellTitle6: "Butterfly's Embrace",
  constellDesc6:
    "This constellation is triggered when Hu Tao's HP falls below 25%, or when she suffers a lethal strike. <br />In this state, Hu Tao will not fall despite the lethal strike. <br />Additionally, she gain 200% increase in her physical and all elemental resonance, and 100% increase in CRIT RATE for the next 10 seconds. <br />This can be triggered automatically when Hu Tao has only 1HP left. <br />Butterfly's Embrace can come to action once every 60 seconds in Genshin Impact."
};
