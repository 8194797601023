import infoPic from "api/characters/infoPic";

const characterName = "amber";

infoPic[characterName];

const amber = {
  avatar: infoPic[characterName].avatar,
  rarity: 4,
  element: "pyro",
  weapon: "bow",
  area: "mond",
  release: true,
  ascensionMaterial: ["agnidus", "everflame_seed", "small_lamp_grass", "arrowhead", "mora"],
  talentMaterial: ["freedom", "arrowhead", "dvalin_sigh", "crown", "mora"],
  uniqueStatus: "atk",
  status: {
    key: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
    level: ["1", "20", "20+", "40", "40+", "50", "50+", "60", "60+", "70", "70+", "80", "80+", "90"],
    baseHP: [793, 2038, 2630, 3940, 4361, 5016, 5578, 6233, 6654, 7309, 7730, 8385, 8806, 9461],
    baseATK: [19, 48, 62, 93, 103, 118, 131, 147, 157, 172, 182, 198, 208, 223],
    baseDEF: [50, 129, 167, 250, 277, 318, 354, 396, 422, 464, 491, 532, 559, 601],
    uniqueStatus: [0, 0, 0, 0, 6, 6, 12, 12, 12, 12, 18, 18, 24, 24],
    ascension: [null, 0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, undefined]
  },
  talents: {
    key: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    charLevel: [1, 2, 3, 3, 4, 4, 5, 5, 6, 6],
    ascension: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  },
  combatTalents: [
    {
      icon: infoPic[characterName].combat[0],
      title: "normalAttack",
      // videoUrl: {
      //   "en-US": "https://www.youtube.com/embed/xDxsppKte_0",
      //   "ko-KR": "https://www.youtube.com/embed/8XN2BCzapZc"
      // },
      detail: [
        {
          title: "normalAttackTitle",
          text: "normalAttackText"
        },
        {
          title: "chargedAttackTitle",
          text: "chargedAttackText"
        },
        {
          title: "plungingAttackTitle",
          text: "plungingAttackText"
        }
      ],
      levelDetail: {
        leftTitle: ["hitDmg1", "hitDmg2", "hitDmg3", "hitDmg4", "hitDmg5", "aimShotDmg", "aimChargedShotDmg", "plungeDmg", "lowHighPlungeDmg"],
        level: [
          {
            value: ["36.1%", "39.1%", "42%", "46.2%", "49.1%", "52.5%", "57.1%", "61.7%", "66.4%", "71.4%", "76.4%", "81.4%", "86.5%", "91.6%", "96.6%"]
          },
          {
            value: ["36.12%", "39.06%", "42%", "46.2%", "49.14%", "52.5%", "57.12%", "61.74%", "66.36%", "71.4%", "76.44%", "81.48%", "86.52%", "91.56%", "96.6%"]
          },
          {
            value: ["46.44%", "50.22%", "54%", "59.4%", "63.18%", "67.5%", "73.44%", "79.38%", "85.32%", "91.8%", "98.28%", "104.76%", "111.24%", "117.72%", "124.2%"]
          },
          {
            value: ["47.3%", "51.15%", "55%", "60.5%", "64.35%", "68.75%", "74.8%", "80.85%", "86.9%", "93.5%", "100.1%", "106.7%", "113.3%", "119.9%", "126.5%"]
          },
          {
            value: ["59.34%", "64.17%", "69%", "75.9%", "80.73%", "86.25%", "93.84%", "101.43%", "109.02%", "117.3%", "125.58%", "133.86%", "142.14%", "150.42%", "158.7%"]
          },
          {
            value: ["43.86%", "47.43%", "51%", "56.1%", "59.67%", "63.75%", "69.36%", "74.97%", "80.58%", "86.7%", "92.82%", "98.94%", "105.06%", "111.18%", "117.3%"]
          },
          {
            value: ["124%", "133.3%", "142.6%", "155%", "164.3%", "173.6%", "186%", "198.4%", "210.8%", "223.2%", "235.6%", "248%", "263.5%", "279%", "294.5%"]
          },
          {
            value: ["56.83%", "61.45%", "66.08%", "72.69%", "77.31%", "82.6%", "89.87%", "97.14%", "104.41%", "112.34%", "120.27%", "128.2%", "136.12%", "144.05%", "151.98%"]
          },
          {
            value: [
              "113.63% / 141.93%",
              "122.88% / 153.49%",
              "132.13% / 165.04%",
              "145.35% / 181.54%",
              "154.59% / 193.1%",
              "165.17% / 206.3%",
              "179.7% / 224.45%",
              "194.23% / 242.61%",
              "208.77% / 260.76%",
              "224.62% / 280.57%",
              "240.48% / 300.37%",
              "256.34% / 320.18%",
              "272.19% / 339.98%",
              "288.05% / 359.79%",
              "303.9% / 379.59%"
            ]
          }
        ]
      }
    },
    {
      icon: infoPic[characterName].combat[1],
      title: "eSkillTitle",
      // videoUrl: {
      //   "en-US": "https://www.youtube.com/embed/xDxsppKte_0",
      //   "ko-KR": "https://www.youtube.com/embed/8XN2BCzapZc"
      // },
      description: "eSkillDesc",
      detail: [
        {
          title: "eSkillEffect1",
          text: ["eSkillEffect1Text1", "eSkillEffect1Text2", "eSkillEffect1Text3"]
        },
        {
          title: "eSkillEffect2",
          text: ["eSkillEffect2Text1", "eSkillEffect2Text2"]
        }
      ],
      skillEnd: "eSkillEffectEnd",
      // quote: "eSkillQuote",
      levelDetail: {
        leftTitle: ["eSkillEffectTitle1", "eSkillEffectTitle2", "skillCD"],
        level: [
          {
            value: ["41.36%", "44.46%", "47.56%", "51.7%", "54.8%", "57.9%", "62.04%", "66.18%", "70.31%", "74.45%", "78.58%", "82.72%", "87.89%", "93.06%", "98.23%"]
          },
          {
            value: ["123.2%", "132.44%", "141.68%", "154%", "163.24%", "172.48%", "184.8%", "197.12%", "209.44%", "221.76%", "234.08%", "246.4%", "261.8%", "277.2%", "292.6%"]
          },
          {
            value: [15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15],
            unit: "second"
          }
        ]
      }
    },
    {
      icon: infoPic[characterName].combat[2],
      title: "qSkillTitle",
      description: "qSkillDesc",
      quote: "qSkillQuote",
      levelDetail: {
        leftTitle: ["qSkillEffectTitle1", "qSkillEffectTitle2", "skillDuration", "skillCD", "skillEnergyCost"],
        level: [
          {
            value: ["28.08%", "30.19%", "32.29%", "35.1%", "37.21%", "39.31%", "42.12%", "44.93%", "47.74%", "50.54%", "53.35%", "56.16%", "59.67%", "63.18%", "66.69%"]
          },
          {
            value: [
              "505.44%",
              "543.35%",
              "581.26%",
              "631.8%",
              "669.71%",
              "707.62%",
              "758.16%",
              "808.7%",
              "859.25%",
              "909.79%",
              "960.34%",
              "1010.88%",
              "1074.06%",
              "1137.24%",
              "1200.42%"
            ]
          },
          {
            value: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
            unit: "second"
          },
          {
            value: [12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12],
            unit: "second"
          },
          {
            value: [40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40]
          }
        ]
      }
    }
  ],
  passiveTalents: [
    {
      icon: infoPic[characterName].passive[0],
      title: "passiveTitle1",
      description: "passiveDesc1",
      unLock: "passiveUnlock1"
    },
    {
      icon: infoPic[characterName].passive[1],
      title: "passiveTitle2",
      description: "passiveDesc2",
      unLock: "passiveUnlock2"
    },
    {
      icon: infoPic[characterName].passive[2],
      title: "passiveTitle3",
      description: "passiveDesc3"
    }
  ],
  constellation: [
    {
      icon: infoPic[characterName].constellation[0],
      title: "constellTitle1",
      description: "constellDesc1"
    },
    {
      icon: infoPic[characterName].constellation[1],
      title: "constellTitle2",
      description: "constellDesc2"
    },
    {
      icon: infoPic[characterName].constellation[2],
      title: "constellTitle3",
      description: "constellDesc3"
    },
    {
      icon: infoPic[characterName].constellation[3],
      title: "constellTitle4",
      description: "constellDesc4"
    },
    {
      icon: infoPic[characterName].constellation[4],
      title: "constellTitle5",
      description: "constellDesc5"
    },
    {
      icon: infoPic[characterName].constellation[5],
      title: "constellTitle6",
      description: "constellDesc6"
    }
  ]
};

export default amber;
