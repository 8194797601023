import React, { useEffect } from "react";
import { useParams, useLocation, Navigate } from "react-router-dom";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { PageHeader, Collapse } from "antd";

import BasicInfo from "components/Character/BasicInfo";
import Ascension from "components/Character/Ascension";
import AscensionTalent from "components/Character/AscensionTalent";
import CombatTalent from "components/Character/CombatTalent";
import PassiveTalent from "components/Character/PassiveTalent";
import Constellation from "components/Character/Constellation";

import characters from "api/characters/detail";

const { Panel } = Collapse;

const Charater: React.FC<any> = ({ match, ...props }) => {
  const { pathname } = useLocation();
  const { id } = useParams();

  const char: string = id!.toLowerCase();

  const { t: ct } = useTranslation("Character");
  const { t } = useTranslation(`Character/${char}`);
  const info = (characters as any)[char];

  if (!info) {
    return <Navigate to={`/`} />;
  }

  const breadRoute = pathname.split("/");

  const routes = [
    {
      path: "",
      breadcrumbName: ct("home")
    },
    {
      path: "/character",
      breadcrumbName: ct("character")
    },
    {
      path: "/character/release",
      breadcrumbName: ct(breadRoute[breadRoute.length - 2].toLowerCase())
    },
    {
      path: breadRoute[breadRoute.length - 1].toLowerCase(),
      breadcrumbName: t("name")
    }
  ];

  function callback(key: any) {
    console.log(key);
  }

  useEffect(() => {}, []);

  return (
    <Wrap>
      <PageHeader className="site-page-header" title={t("name")} subTitle={t("nickName")} breadcrumb={{ routes }} />
      <BasicInfo char={char} info={info} />
      <CollapseWrap defaultActiveKey={["1", "2", "3", "4", "5"]} onChange={callback} bordered={false}>
        <PanelWrap header={ct("ascensionStatus")} key="1">
          <Ascension status={info.status} uniqueStatus={info.uniqueStatus} material={info.ascensionMaterial} />
        </PanelWrap>
        <PanelWrap header={ct("talentStatus")} key="2">
          <AscensionTalent talents={info.talents} material={info.talentMaterial} />
        </PanelWrap>
        <PanelWrap header={ct("combatTalents")} key="3">
          <CombatTalent char={char} combatTalents={info.combatTalents} />
        </PanelWrap>
        <PanelWrap header={ct("passiveTalents")} key="4">
          <PassiveTalent char={char} passiveTalents={info.passiveTalents} />
        </PanelWrap>
        <PanelWrap header={ct("constellation")} key="5">
          <Constellation char={char} element={info.element} constellation={info.constellation} />
        </PanelWrap>
      </CollapseWrap>
    </Wrap>
  );
};

export default Charater;

const Wrap = styled.div`
  position: relative;
  color: ${p => p.theme.COLORS.TEXT_PRIMARY};
  ${p => p.theme.TEXTS.BOARD_CONTENT};
`;

const CollapseWrap = styled(Collapse)`
  margin-top: 25px;
  background: transparent;
`;

const PanelWrap = styled(Panel)`
  & + div {
    margin-top: 25px;
  }

  & .ant-collapse-header {
    font-size: 18px;
    font-weight: bold;

    & .ant-collapse-arrow {
      top: 50% !important;
      margin-top: -18px;
    }
  }

  &.ant-collapse-item {
    background: #fafafa;
  }

  & .ant-descriptions-item-label {
    padding: 6px 8px;
    font-weight: bold;
  }

  & .ant-descriptions-item-content {
    padding: 6px 8px;
  }

  & p + .ant-descriptions {
    margin-top: 24px;
  }

  & .ant-card + .ant-card {
    margin-top: 24px;
  }

  & .ant-row + .ant-table-wrapper {
    margin-top: 24px;
  }

  & .ant-card-head {
    padding: 0 16px;
  }

  & .ant-card-body {
    padding: 16px;
  }

  @media only screen and (max-width: 769px) {
    & .ant-card-head {
      padding: 0 8px;
    }

    & .ant-card-body {
      padding: 8px;
    }

    & .ant-collapse-content-box {
      padding: 8px;
    }
  }
`;
