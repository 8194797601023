import mora_pic from "assets/item/mora.png";

const req = require.context("../assets/item", true, /^(?!.\/index).*.png$/);

const agate: { [key: string]: { [key: string]: string }[] } = {};
const stone: { [key: string]: { [key: string]: string } } = {};
const local: { [key: string]: { [key: string]: string } } = {};
const second: { [key: string]: { [key: string]: string }[] } = {};
const mora = { title: "mora", icon: mora_pic };

req.keys().forEach((key: string) => {
  const regex = /.\/(.*?).png$/;
  const regex_underbar = /_[abcdefghijk]_/;
  const agate_pic = key.indexOf("agate_") > 0 && req(key).default;
  const stone_pic = key.indexOf("stone_") > 0 && req(key).default;
  const local_pic = key.indexOf("local_") > 0 && req(key).default;

  const moduleName = ((regex.test(key) && key.match(regex)![1]) as string).split("_");

  if (agate_pic) {
    const title = key.match(regex)![1].replace(regex_underbar, "_");
    if (typeof agate[moduleName[1]] === "object") {
      agate[moduleName[1]].push({ title, icon: agate_pic });
    } else {
      agate[moduleName[1]] = [{ title, icon: agate_pic }];
    }
  }

  if (stone_pic) {
    const moduleName = key.match(regex)![1].split("stone_")[1];
    stone[moduleName] = { title: moduleName, icon: stone_pic };
  }

  if (local_pic) {
    const moduleName = key.match(regex)![1].split("local_")[1];
    local[moduleName] = { title: moduleName, icon: local_pic };
  }

  if (!agate_pic && !stone_pic && !local_pic && moduleName[1]) {
    const title = key.match(regex)![1].replace(regex_underbar, "_");
    if (typeof second[moduleName[0]] === "object") {
      second[moduleName[0]].push({ title, icon: req(key).default });
    } else {
      second[moduleName[0]] = [{ title, icon: req(key).default }];
    }
  }
});

export default { agate, stone, local, second, mora };
