import React, { useEffect } from "react";
import styled from "@emotion/styled";

import ShortInfo from "components/Character/ShortInfo";

import release from "api/characters/release";

const Release: React.FC = () => {
  useEffect(() => {}, []);

  return (
    <Wrap>
      {release.map((item, index) => (
        <ShortInfo key={index} info={item} />
      ))}
    </Wrap>
  );
};

export default Release;

const Wrap = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  position: relative;
  color: ${p => p.theme.COLORS.TEXT_PRIMARY};
  ${p => p.theme.TEXTS.BOARD_CONTENT};
`;
