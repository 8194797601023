import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Table } from "antd";

import TalentMaterial from "components/Character/TalentMaterial";

const AscensionComponent: React.FC<any> = ({ talents, material }) => {
  const { t: ct } = useTranslation("Character");

  const [statusReverse, setStatusReverse] = useState<boolean>(false);

  const sortTable = (pagination: any, filters: any, sorter: any) => {
    setStatusReverse(sorter.order === "descend" ? true : false);
  };

  const ascenstionStatusColumns = [
    {
      title: ct("level"),
      dataIndex: "level",
      key: "level",
      sorter: (a: any, b: any) => a.level - b.level
    },
    {
      title: ct("characterPhase"),
      dataIndex: "charLevel",
      key: "charLevel",
      render(_: any, row: any) {
        return {
          children: <span>{row.charLevel}</span>,
          props: {
            rowSpan: row.rowSpan
          }
        };
      }
    },
    {
      title: ct("ascension"),
      dataIndex: "ascension",
      key: "ascension",
      render(_: any, row: any) {
        return {
          children: <TalentMaterial material={material} rank={row.ascension} />
        };
      }
    }
  ];

  const sameJoinTd = (data: any, sameTd: string) => {
    return data
      .reduce((result: any, item: any) => {
        //First, take the name field as a new array result
        if (result.indexOf(item[sameTd]) < 0) {
          result.push(item[sameTd]);
        }
        return result;
      }, [])
      .reduce((result: any, sameTdValue: any) => {
        //Take the data with the same name as a new array, and add a new field * * rowSpan inside it**
        const children = data.filter((item: any) => item[sameTd] === sameTdValue);
        result = result.concat(
          children.map((item: any, index: any) => ({
            ...item,
            rowSpan: index === 0 ? children.length : 0 //Add the first row of data to the rowSpan field
          }))
        );
        return result;
      }, []);
  };

  const statusExtend = talents.key.reduce((acc: any[], cur: any, index: number) => {
    acc.push({
      key: talents.key[index],
      level: talents.key[index],
      charLevel: talents.charLevel[index],
      ascension: talents.ascension[index]
    });
    return acc;
  }, []);

  return (
    <>
      <Table
        className={"ascension-status"}
        columns={ascenstionStatusColumns}
        sortDirections={["descend", "ascend"]}
        dataSource={sameJoinTd(statusReverse ? [...statusExtend].reverse() : statusExtend, "charLevel")}
        size={"middle"}
        onChange={sortTable}
        pagination={false}
        bordered
      />
    </>
  );
};

export default AscensionComponent;
