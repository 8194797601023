import React from "react";
import styled from "styles/theme";
import { useTranslation } from "react-i18next";

import { Card, Col, Row, Divider } from "antd";
import { YoutubeFilled } from "@ant-design/icons";

import utils from "utils";

const { common } = utils;

const PassiveTalentComponent: React.FC<any> = ({ char, passiveTalents }) => {
  const { t: ct } = useTranslation("Character");
  const { t } = useTranslation(`Character/${char}`);

  const flexSet = common.chkMobile() ? ["100%", "auto"] : ["100px", "auto"];

  return (
    <PassiveTalent>
      {passiveTalents.map((item: any, index: number) => (
        <Card type="inner" key={index}>
          <Row gutter={16}>
            <Col flex={flexSet[0]}>
              <TalentIcon>
                <img alt={`${t(item.title)} Icon`} src={item.icon} />
              </TalentIcon>
              {item.videoUrl && (
                <VideoIcon>
                  <YoutubeFilled style={{ fontSize: "50px" }} />
                </VideoIcon>
              )}
            </Col>
            <Col flex={flexSet[1]}>
              <Title>{t(item.title)}</Title>
              {item.description && <p dangerouslySetInnerHTML={{ __html: t(item.description) }} />}
            </Col>
          </Row>
          {item.unLock && (
            <>
              <Divider />
              <UnLockDesc key={index} dangerouslySetInnerHTML={{ __html: t(item.unLock) }} />
              <Divider />
            </>
          )}
        </Card>
      ))}
    </PassiveTalent>
  );
};

export default PassiveTalentComponent;

const PassiveTalent = styled.div`
  .thum-video {
    @media only screen and (max-width: 769px) {
      margin-bottom: 10px;
    }
  }
`;

const TalentIcon = styled(Card)`
  display: inline-block;
  max-width: 100px;
  vertical-align: middle;

  .ant-card-body {
    padding: 5px;
    background: #ddd;
    text-align: center;

    img {
      width: 100%;
      max-width: 100px;
    }
  }
`;

const VideoIcon = styled.div`
  display: inline-block;
  padding: 15px 0;
  width: 100px;
  text-align: center;
  vertical-align: middle;
`;

const Title = styled.h2`
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
`;

const UnLockDesc = styled.p`
  padding: 0 10px;
`;
