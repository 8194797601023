import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import characters from "api/characters/detail";
import avatar from "api/characters/avatar";

import { incrementCount, decrementCount, resetCount } from "store/reducers/System";

const HomeView: React.FC<any> = ({ user, ...props }) => {
  const { t } = useTranslation("cAmber");
  const count = useSelector((store: IStore) => store.System.count);
  const dispatch = useDispatch();

  return (
    <Wrap>
      <p>
        <strong>{count}</strong>
      </p>
      {/* <button type="button" onClick={() => dispatch(incrementCount())}>
        증가
      </button>
      <button type="button" onClick={() => dispatch(decrementCount())}>
        감소
      </button>
      <button type="button" onClick={() => dispatch(resetCount())}>
        리셋
      </button> */}
      {/* <p>{t(characters.amber.affiliation)}</p> */}
    </Wrap>
  );
};

export default HomeView;

const Wrap = styled.div`
  position: relative;
  color: ${p => p.theme.COLORS.TEXT_PRIMARY};
  ${p => p.theme.TEXTS.BOARD_CONTENT};
`;
