import React from "react";
import styled from "styles/theme";
import { useTranslation } from "react-i18next";

import { Card, Col, Row, Divider } from "antd";

import utils from "utils";

const { common } = utils;

const ConstellationComponent: React.FC<any> = ({ char, element, constellation }) => {
  const { t: ct } = useTranslation("Character");
  const { t } = useTranslation(`Character/${char}`);

  const flexSet = common.chkMobile() ? ["100%", "auto"] : ["100px", "auto"];
  const autoLine = common.chkMobile() ? true : false;

  return (
    <>
      {constellation.map((item: any, index: number) => (
        <Card type="inner" key={index}>
          <Row gutter={16} wrap={autoLine}>
            <Col flex={flexSet[0]}>
              <TalentIcon className={element}>
                <img alt={`${t(item.title)} Icon`} src={item.icon} />
              </TalentIcon>
            </Col>
            <Col flex={flexSet[1]}>
              <Title>{t(item.title)}</Title>
              {item.description && <p dangerouslySetInnerHTML={{ __html: t(item.description) }} />}
            </Col>
          </Row>
        </Card>
      ))}
    </>
  );
};

export default ConstellationComponent;

const TalentIcon = styled(Card)`
  margin-bottom: 20px;
  max-width: 80px;

  .ant-card-body {
    padding: 5px;
    background: rgba(0, 0, 0, 0.6);
    text-align: center;

    img {
      width: 100%;
      max-width: 100px;
    }
  }

  border-radius: 50%;
  overflow: hidden;
  border: 1px solid;
`;

const Title = styled.h2`
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
`;
