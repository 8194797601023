import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Table } from "antd";

import Material from "components/Character/Material";

const AscensionComponent: React.FC<any> = ({ status, uniqueStatus, material }) => {
  const { t: ct } = useTranslation("Character");

  const [statusReverse, setStatusReverse] = useState<boolean>(false);

  const sortTable = (pagination: any, filters: any, sorter: any) => {
    setStatusReverse(sorter.order === "descend" ? true : false);
  };

  const ascenstionStatusColumns = [
    {
      title: ct("level"),
      dataIndex: "level",
      key: "level",
      sorter: (a: any, b: any) => a.baseATK - b.baseATK
    },
    {
      title: ct("baseHP"),
      dataIndex: "baseHP",
      key: "baseHP"
    },
    {
      title: ct("baseATK"),
      dataIndex: "baseATK",
      key: "baseATK"
    },
    {
      title: ct("baseDEF"),
      dataIndex: "baseDEF",
      key: "baseDEF"
    },
    {
      title: ct(uniqueStatus),
      dataIndex: "uniqueStatus",
      key: "uniqueStatus"
    },
    {
      title: ct("ascension"),
      dataIndex: "ascension",
      key: "ascension",
      render(_: any, row: any) {
        return {
          children: <Material material={material} rank={row.ascension} />,
          props: {
            rowSpan: row.rowSpan
          }
        };
      }
    }
  ];

  const sameJoinTd = (data: any, sameTd: string) => {
    return data
      .reduce((result: any, item: any) => {
        //First, take the name field as a new array result
        if (result.indexOf(item[sameTd]) < 0) {
          result.push(item[sameTd]);
        }
        return result;
      }, [])
      .reduce((result: any, sameTdValue: any) => {
        //Take the data with the same name as a new array, and add a new field * * rowSpan inside it**
        const children = data.filter((item: any) => item[sameTd] === sameTdValue);
        result = result.concat(
          children.map((item: any, index: any) => ({
            ...item,
            rowSpan: index === 0 ? children.length : 0 //Add the first row of data to the rowSpan field
          }))
        );
        return result;
      }, []);
  };

  const statusExtend = status.key.reduce((acc: any[], cur: any, index: number) => {
    acc.push({
      key: status.key[index],
      level: status.level[index],
      baseHP: status.baseHP[index],
      baseATK: status.baseATK[index],
      baseDEF: status.baseDEF[index],
      uniqueStatus: status.uniqueStatus[index],
      ascension: status.ascension[index]
    });
    return acc;
  }, []);

  return (
    <>
      <Table
        className={"ascension-status"}
        columns={ascenstionStatusColumns}
        sortDirections={["descend", "ascend"]}
        dataSource={sameJoinTd(statusReverse ? [...statusExtend].reverse() : statusExtend, "ascension")}
        size={"middle"}
        onChange={sortTable}
        pagination={false}
        bordered
      />
    </>
  );
};

export default AscensionComponent;
