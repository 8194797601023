import r4_polearm_favonius_lance from "assets/weapons/r4_polearm_favonius_lance.png";

const polearm = {
  favoniusLance: {
    icon: r4_polearm_favonius_lance,
    rarity: 4,
    ascensionMaterial: [],
    uniqueStatus: "energyRecharge",
    status: {
      key: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
      level: ["1", "20", "20+", "40", "40+", "50", "50+", "60", "60+", "70", "70+", "80", "80+", "90"],
      baseATK: [44, 119, 144, 226, 252, 293, 319, 361, 387, 429, 455, 497, 523, 565],
      uniqueStatus: [6.7, 11.8, 11.8, 17.2, 17.2, 19.9, 19.9, 22.6, 22.6, 25.2, 25.2, 27.9, 27.9, 30.6],
      ascension: [null, 0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, undefined]
    },
    ability: {
      title: "windFall",
      description: "windFallDesc"
    },
    refinement: [10, 1.5],
    description: "favoniusLanceDesc"
  }
};

export default polearm;
