import React from "react";
import { Link, Navigate } from "react-router-dom";
import { getDefaultLocale } from "locales";

export const linkMove = (url: string) => {
  const currentLang = getDefaultLocale();
  if (url.indexOf("http") > -1) {
    const openWindow = window.open("about: blank");
    openWindow!.location.href = url;
  } else {
    return <Navigate to={`/${currentLang}${url}`} />;
  }

  event?.preventDefault();
};

const LinkLocalComponent: React.FC<any> = ({ url, children }) => {
  const currentLang = getDefaultLocale();

  const outUrlChk = url.indexOf("http") > -1 ? true : false;

  return outUrlChk ? (
    <a href={url} target={"_blank"} rel="noreferrer">
      {children}
    </a>
  ) : (
    <Link to={`/${currentLang}${url}`}>{children}</Link>
  );
};

export default LinkLocalComponent;
