export default {
  home: "홈",
  character: "캐릭터",
  release: "출시",
  unrelease: "미출시",
  birthday: "생일",
  constellation: "운명의 자리",
  cityState: "지역",
  affiliation: "소속",
  voice: "성우",
  description: "설명",
  ascensionStatus: "레벨 돌파 상태",
  level: "레벨",
  baseHP: "기초 HP",
  baseATK: "기초 공격력",
  baseDEF: "기초 방어력",
  atk: "공격력 %",
  critDmg: "치명타 피해 %",
  critRate: "치명타 확률 %",
  ascension: "돌파 재료",
  combatTalents: "전투 특성",
  passiveTalents: "고유 특성",
  talentsLevel: "레벨 ",
  talentStatus: "특성 돌파 상태",
  characterPhase: "캐릭터 돌파"
};
