export default {
  agate_agnidus_sliver: "불타오르는 마노 가루",
  agate_agnidus_fragment: "불타오르는 마노 조각",
  agate_agnidus_chunk: "불타오르는 마노 덩이",
  agate_agnidus_gemstone: "불타오르는 마노",
  juvenile_jade: "설익은 옥",
  silk_flower: "예상꽃",
  nectar_whopperflower: "구라구라 꽃꿀",
  nectar_shimmering: "반짝반짝 꽃꿀",
  nectar_energy: "원소 꽃꿀",
  mora: "모라"
};
