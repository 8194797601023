export default {
  name: "Amber",
  nickName: "Gliding Champion",
  birthday: "10 August",
  cityState: "Mondstadt",
  affiliation: "Knights of Favonius",
  constellation: "Lepus",
  description: ["Always energetic and full of life, Amber's the best — albeit only — Outrider of the Knights of Favonius."],
  voice: ["Kelly Baskin", "김연우", "蔡书瑾", "石見舞菜香"],
  normalAttack: "Normal Attack: Sharpshooter",
  normalAttackTitle: "일반 공격",
  normalAttackText: "Perform up to 5 consecutive shots with a bow.",
  chargedAttackTitle: "강공격",
  chargedAttackText:
    "Perform a more precise <strong class='ffd780ff'>Aimed Shot with increased DMG.</strong> While aiming, flames will accumulate on the arrowhead. A fully charged flaming arrow will deal <strong class='pyro'>Pyro DMG.</strong>",
  plungingAttackTitle: "Plunging Attack",
  plungingAttackText: "Fires off a shower of arrows in mid-air before falling and striking the ground, dealing AoE DMG upon impact.",
  hitDmg1: "1-Hit DMG",
  hitDmg2: "2-Hit DMG",
  hitDmg3: "3-Hit DMG",
  hitDmg4: "4-Hit DMG",
  hitDmg5: "5-Hit DMG",
  aimShotDmg: "Aimed Shot",
  aimChargedShotDmg: "Fully-Charged Aimed Shot",
  plungeDmg: "Plunge DMG",
  lowHighPlungeDmg: "Low/High Plunge DMG",
  eSkillTitle: "Explosive Puppet",
  eSkillDesc: "The ever-reliable Baron Bunny takes the stage.",
  eSkillEffect1: "Baron Bunny",
  eSkillEffect1Text1: "Continuously taunts the enemy, drawing their fire.",
  eSkillEffect1Text2: "Baron Bunny's HP scales with Amber's Max HP.",
  eSkillEffect1Text3: " • When destroyed or when its timer expires, Baron Bunny explodes, dealing <strong class='pyro'>AoE Pyro DMG</strong>.",
  eSkillEffect2: "Hold",
  eSkillEffect2Text1: "Adjusts the throwing direction of Baron Bunny.",
  eSkillEffect2Text2: "The longer the button is held, the further the throw.",
  eSkillEffectEnd: "Baron Bunny hails from a storied lineage, but Amber has stopped keeping count since Baron Bunny the 893rd.",
  eSkillEffectTitle1: "Inherited HP",
  eSkillEffectTitle2: "Explosion DMG",
  qSkillTitle: "Fiery Rain",
  qSkillDesc: "Fires off a shower of arrows, dealing continuous <strong class='pyro'>AoE Pyro DMG</strong>.",
  qSkillQuote: "Amber's passion truly burns hot.",
  qSkillEffectTitle1: "Fiery Rain DMG Per Wave",
  qSkillEffectTitle2: "Total Fiery Rain DMG",
  skillDuration: "Duration",
  skillCD: "CD",
  skillEnergyCost: "Energy Cost",
  second: "s",
  passiveTitle1: "Every Arrow Finds Its Target",
  passiveDesc1: "Increases the CRIT Rate of Fiery Rain by 10% and widens its AoE by 30%.",
  passiveUnlock1: "Unlock at Character Ascension Phase 1",
  passiveTitle2: "Precise Shot",
  passiveDesc2: "Aimed Shot hits on weak points increase ATK by 15% for 10s.",
  passiveUnlock2: "Unlock at Character Ascension Phase 4",
  passiveTitle3: "Gliding Champion",
  passiveDesc3: "Decreases gliding Stamina consumption for your own party members by 20%. Not stackable with Passive Talents that provide the exact same effects.",
  constellTitle1: "One Arrow to Rule Them All",
  constellDesc1: "Fires 2 arrows per Aimed Shot. The second arrow deals 20% of the first arrow's DMG.",
  constellTitle2: "Bunny Triggered",
  constellDesc2:
    "Baron Bunny, new and improved! Hitting Baron Bunny's foot with a fully-charged Aimed Shot manually detonates it.<br /> Explosion via manual detonation deals 200% additional DMG.",
  constellTitle3: "It Burns!",
  constellDesc3: "It increases the level of Fiery Rain by 3. levels.",
  constellTitle4: "It's Not Just Any Doll...",
  constellDesc4: "Decreases Explosive Puppet's CD by 20%. Adds 1 additional charge.",
  constellTitle5: "It's Baron Bunny!",
  constellDesc5: "It increases the level of Explosive Puppet by 3. levels.",
  constellTitle6: "Wildfire",
  constellDesc6: "Fiery Rain increases all party members' Movement SPD by 15% and ATK by 15% for 10s."
};
