export default {
  name: "엠버",
  nickName: "비행 챔피언",
  birthday: "8월 10일",
  cityState: "몬드",
  affiliation: "페보니우스 기사단",
  constellation: "토끼 자리",
  description: [
    "엠버는 페보니우스 기사단의 정찰 기사다. 정찰 기사가 몰락해버린 오늘날, 그녀는 홀로 정찰 기사의 의무를 지킨다.",
    "몬드에 처음 방문한 여행객들은 3일도 안 돼서 이 불같은 열정을 지닌 이 소녀와 친구가 된다.",
    "「디어 헌터」 간판 앞, 시드르 호수 물가, 바람이 시작되는 곳의 참나무 꼭대기 위 등 모든 곳에 이 빨간색 기사가 남긴 발자국이 있다.",
    "그녀에게 「수상한 인물」로 찍힌다면 절대 기사의 진지한 심문에서 벗어나지 못한다."
  ],
  voice: ["김연우", "Kelly Baskin", "蔡书瑾", "石見舞菜香"],
  normalAttack: "일반 공격 · 신궁",
  normalAttackTitle: "일반 공격",
  normalAttackText: "활로 최대 5번 공격한다.",
  chargedAttackTitle: "강공격",
  chargedAttackText:
    "피해가 더 크고 정확한 <strong class=''>조준 사격</strong>을 한다. 조준 시 뜨거운 화염이 화살 촉에 계속해서 모인다. 화염의 힘이 가득 찬 화살은 <strong class='pyro'>불 원소 피해</strong>를 준다.",
  plungingAttackTitle: "낙하 공격",
  plungingAttackText: "공중에서 화살비를 쏜 후 빠른 속도로 땅에 착지한다. 착지 시 범위 피해를 준다.",
  hitDmg1: "1단 공격 피해",
  hitDmg2: "2단 공격 피해",
  hitDmg3: "3단 공격 피해",
  hitDmg4: "4단 공격 피해",
  hitDmg5: "5단 공격 피해",
  aimShotDmg: "조준 사격",
  aimChargedShotDmg: "풀차지 조준 사격",
  plungeDmg: "낙하 기간 피해",
  lowHighPlungeDmg: "저공/고공 추락 충격 피해",
  eSkillTitle: "폭탄 인형",
  eSkillDesc: "믿음직한 토끼 백작을 전장에 모신다.",
  eSkillEffect1: "토끼 백작",
  eSkillEffect1Text1: "주변의 적을 도발하여 공격을 유도한다.",
  eSkillEffect1Text2: "토끼 백작은 엠버의 HP 최대치를 일정 비율 계승한다.",
  eSkillEffect1Text3: "파괴당하거나 지속 시간이 끝나면 폭발하여 <strong class='pyro'>불 원소 피해</strong>를 준다",
  eSkillEffect2: "홀드",
  eSkillEffect2Text1: "던지는 방향을 조정한다.",
  eSkillEffect2Text2: "투척 거리는 누르는 시간에 따라 늘어난다.",
  eSkillEffectEnd: "토끼 백작은 집안 대대로 이어 온 계보가 있다. 하지만 토끼 백작 893세부터 엠버는 숫자를 세는 걸 포기했다.",
  eSkillEffectTitle1: "HP 계승",
  eSkillEffectTitle2: "폭발 피해",
  qSkillTitle: "화살비",
  qSkillDesc: "전방에 화염 화살비를 쏴 적에게 <strong class='pyro'>불 원소 피해</strong>를 계속 준다.",
  qSkillQuote: "정열적인 엠버는 정말로 뜨겁다.",
  qSkillEffectTitle1: "화살비 단일 피해",
  qSkillEffectTitle2: "화살비 총 피해",
  skillDuration: "지속 시간",
  skillCD: "재사용 <br/>대기시간",
  skillEnergyCost: "원소 에너지",
  currentHP: "현재 HP의 %%",
  maxHP: "HP 최대치의 %%",
  second: "초",
  passiveTitle1: "백발백중!",
  passiveDesc1: "화살비의 치명타 확률이 10% 증가하고 영향 범위가 30% 확대된다.",
  passiveUnlock1: "캐릭터 Lv.1 돌파",
  passiveTitle2: "제압 사격",
  passiveDesc2: "조준 사격이 약점에 명중하면 기초 공격력이 15% 증가한다. 지속 시간: 10초",
  passiveUnlock2: "캐릭터 Lv.4 돌파",
  passiveTitle3: "비행 챔피언",
  passiveDesc3: "파티 내 자신의 캐릭터의 활강 시 소모하는 스태미나가 20% 감소한다. 같은 효과의 고유 특성과 중첩되지 않는다.",
  constellTitle1: "일석이츄츄!",
  constellDesc1: "조준 사격 시 연속으로 2개의 화살을 쏜다. 두 번째 화살로 가하는 피해는 첫 번째 화살의 20%이다.",
  constellTitle2: "일촉즉발",
  constellDesc2:
    "토끼 백작 완전 업그레이드! 풀차지의 조준 사격을 토끼 백작의 발에 명중하면 토끼 백작을 바로 폭발시킬 수 있다...<br /> 이러한 방식으로 폭발시키면 200%의 추가 피해를 준다.",
  constellTitle3: "타오르기 시작했어!",
  constellDesc3: "화살비의 스킬 레벨+3 최대 Lv.15까지 상승",
  constellTitle4: "보통 봉제 인형일 리가 없잖아",
  constellDesc4: "폭탄 인형의 재사용 대기시간이 20% 감소하고 사용 가능 횟수가 1회 추가된다.",
  constellTitle5: "토끼 백작이야!",
  constellDesc5: "폭탄 인형의 스킬 레벨+3 최대 Lv.15까지 상승",
  constellTitle6: "맹렬한 불길",
  constellDesc6: "화살비 발동 후 10초 동안 파티 내 모든 캐릭터의 이동속도가 15%, 공격력이 15% 증가한다."
};
