import nav01 from "assets/icon/nav01.png";
import nav08 from "assets/icon/nav08.png";

export default [
  {
    title: "characters",
    url: "/characters/release",
    icon: nav01,
    depth: [
      {
        title: "release",
        url: "/characters/release"
      },
      {
        title: "unrelease",
        url: "/characters/unrelease"
      }
      // {
      //   title: "unrelease"
      // }
    ]
  },
  {
    title: "map",
    url: "https://webstatic-sea.mihoyo.com/app/ys-map-sea/index.html?utm_source=hoyolab",
    icon: nav08
  }
];
