import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Card, Rate } from "antd";
import LinkLocal from "components/Common/LinkLocal";

import Area from "api/area";
import Avatar from "api/characters/avatar";
import Element from "api/element";
import Weapons from "api/weapons";

const ShortInfoComponent: React.FC<any> = ({ info }) => {
  const { t } = useTranslation(`Character/release`);

  const element = (Element as any)[info.element];
  const weapon = Weapons.common[info.weapon];
  const avatar = (Avatar as any)[info.name];

  return (
    <ShortInfo>
      <LinkLocal url={`/characters/release/${info.name}`}>
        <Card title={t(info.name)} cover={<img alt="example" src={avatar} />} actions={[<img src={element} key="element" />, <img src={weapon} key="weapon" />]}>
          <RateArea disabled count={info.rarity} defaultValue={info.rarity} />
        </Card>
      </LinkLocal>
    </ShortInfo>
  );
};

export default ShortInfoComponent;

const ShortInfo = styled.div`
  margin: 10px;
  max-width: 125px;

  a {
    text-decoration: none;
  }

  .ant-card-head {
    padding: 5px;
    min-height: 0;

    .ant-card-head-wrapper {
      padding: 0;
    }

    .ant-card-head-title {
      position: relative;
      padding: 0 0 0 10px;
      font-weight: bold;

      /* &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 30px;
        background: url(${Area.liyue}) 0 0 no-repeat;
        background-size: 100%;
      } */
    }
  }

  .ant-card-body {
    padding: 0;
    height: 0;
  }

  .ant-card-actions li {
    margin: 8px 0;
  }

  .ant-card-actions li img {
    width: 100%;
    max-width: 48px;
  }

  .ant-descriptions-bordered .ant-descriptions-item-content {
    padding: 10px;
    text-align: center;

    strong {
      display: block;
      text-align: left;
      font-size: 20px;
    }
  }

  .material {
    img {
      width: 44px;
    }
  }
`;

const RateArea = styled(Rate)`
  position: absolute;
  bottom: 65px;
  left: 0;
  padding: 5px 0;
  width: 100%;
  font-size: 0;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  vertical-align: top;

  li {
    margin-right: 2px;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;
