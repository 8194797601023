import React, { useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import styled from "styles/theme";
import { useTranslation } from "react-i18next";

import { Layout, Menu } from "antd";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

import LinkLocal, { linkMove } from "components/Common/LinkLocal";

import { locales, changeLang } from "locales";
import { routeSnap } from "api/reactSnap";
import navApi from "api/nav";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const rootSubmenuKeys = ["characters", "sub1", "sub2"];

export default () => {
  const { t } = useTranslation("common");

  const [collapsed, setCollapsed] = useState<boolean>(window.innerWidth > 768 ? false : true);

  const handleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const resizeChk = (e: any) => {
      const { currentTarget } = e;

      if (currentTarget) {
        setCollapsed(currentTarget.innerWidth < 769 ? true : false);
      }
    };

    window.addEventListener("onload", resizeChk);

    window.addEventListener("resize", resizeChk);
  }, []);

  return (
    <Wrap>
      <Layout>
        <Sider collapsible collapsed={collapsed} onCollapse={handleCollapsed}>
          <div className="logo" />
          <Menu className="gnb" theme="dark" mode="inline">
            {navApi.map((item, index) => (
              <SubMenu
                key={rootSubmenuKeys[index]}
                icon={<img src={item.icon} />}
                title={t(item.title)}
                onTitleClick={() => item.url && linkMove(item.url)}
                className={item.depth ? "" : "not-depth"}
              >
                {item.depth &&
                  item.depth.map((item, index) => (
                    <Menu.Item key={index}>
                      <LinkLocal url={item.url}>{t(item.title)}</LinkLocal>
                    </Menu.Item>
                  ))}
              </SubMenu>
            ))}
          </Menu>
          <LangArea>
            {locales.map((item, index) => (
              <button key={index} onClick={() => changeLang(item)}>
                {item}
              </button>
            ))}
          </LangArea>
          <RouteNav>
            {routeSnap.map((item, index) => (
              <Link to={item} key={index}>
                {item}
              </Link>
            ))}
          </RouteNav>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <button type="button" className="trigger" onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </button>
          </Header>
          <Content>
            <div className="site-layout-background">
              <Outlet />
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}></Footer>
        </Layout>
      </Layout>
      {!collapsed && <SiderDim onClick={() => setCollapsed(!collapsed)} />}
    </Wrap>
  );
};

const Wrap = styled.div`
  .ant-layout-sider {
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: #2c3135;
      z-index: -1;
    }

    .ant-menu-submenu.not-depth {
      .ant-menu-submenu-arrow {
        display: none;
      }
    }

    .ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
    .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
    .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
    .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
      left: 0;
      padding: 0 calc(50% - 40px / 2) !important;
    }
  }

  .site-layout {
    & .ant-layout-content {
      margin: 24px 0;
      padding: 0 24px;
    }

    & .site-layout-background {
      padding: 24px;
      background: #fff;
    }

    @media only screen and (max-width: 769px) {
      & .ant-layout-content {
        margin: 16px 0;
        padding: 0 12px;
      }

      & .site-layout-background {
        padding: 12px;
      }
    }
  }

  .site-layout #components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }

  #components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
  }

  #components-layout-demo-custom-trigger .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }

  .site-page-header {
    text-align: left;
    padding: 0;
  }

  .ant-layout-sider-trigger,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark,
  .ant-menu-sub,
  .ant-layout-sider {
    background: #2c3135;
  }

  .ant-layout-sider-trigger {
    background: #2c3135;
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #23272a;
  }

  .ant-menu-submenu-title {
    padding-left: 5px !important;

    & img {
      position: relative;
      top: -1px;
      width: 40px;
      margin-right: 5px;
      vertical-align: middle;
    }
  }

  .ant-layout-sider-collapsed .ant-menu-submenu-title {
    padding: 0;
    text-align: center;

    & span {
      display: none;
    }
  }

  .logo {
    position: sticky;
    top: 0;
    left: 0;
    margin-bottom: 4px;
    padding: 16px;

    a {
      display: block;
      height: 32px;
      font-size: 0;
      background: #fff;
    }
  }

  .gnb {
    position: sticky;
    top: 36px;
    left: 0;
  }

  .site-layout-background .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }

  @media only screen and (max-width: 769px) {
    .ant-layout-has-sider {
      .ant-layout-sider-has-trigger {
        position: absolute;
        top: 0;
        left: 0;
        min-height: 100vh;
        z-index: 10;
      }

      .ant-layout-sider-collapsed {
        flex: 0 0 0 !important;
        left: -100%;
        width: 0 !important;
        max-width: 0 !important;
        min-width: 0 !important;
        z-index: 0;
      }

      .ant-layout-sider-trigger {
        display: none;
      }
    }
  }
`;

const LangArea = styled.div`
  position: fixed;
  bottom: 48px;
  left: 0;
`;

const SiderDim = styled.div`
  @media only screen and (max-width: 769px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #000a;
    z-index: 5;
  }
`;

const RouteNav = styled.div`
  position: absolute;
  top: -100%;
  left: 0;
  opacity: 0;
`;
