export default {
  name: "호두",
  nickName: "눈 그친 뒤의 매화향",
  birthday: "7월 15일",
  cityState: "리월 항구",
  affiliation: "왕생당",
  constellation: "나비 자리",
  description: [
    "호두——「왕생당」의 77대 당주, 리월의 장례를 주관하는 중요한 인물이다.",
    "최선을 다해 사람들의 장례를 치러주고 세상의 음양 간의 균형을 지킨다.",
    "이 외에도 신기한 시인으로서 수많은 「걸작」이 리월 사람들에 의해 전해지고 있다."
  ],
  voice: ["김하루", "Brianna Knickerbocker", "陶典", "高橋李依"],
  normalAttack: "일반 공격 · 왕생 비법 창술",
  normalAttackTitle: "일반 공격",
  normalAttackText: "창으로 최대 6번 공격한다.",
  chargedAttackTitle: "강공격",
  chargedAttackText: "일정 스태미나를 소모해 전방으로 돌진하며 경로상의 적에게 피해를 준다.",
  plungingAttackTitle: "낙하 공격",
  plungingAttackText: "공중에서 땅을 내려찍어 경로상의 적을 공격하고 착지 시 범위 피해를 준다.",
  hitDmg1: "1단 공격 피해",
  hitDmg2: "2단 공격 피해",
  hitDmg3: "3단 공격 피해",
  hitDmg4: "4단 공격 피해",
  hitDmg5: "5단 공격 피해",
  hitDmg6: "6단 공격 피해",
  chargedAttackDmg: "강공격 피해",
  chargedStaminaCost: "강공격 <br/>스태미나 소모",
  plungeDmg: "낙하 기간 피해",
  lowHighPlungeDmg: "저공/고공 추락 충격 피해",
  eSkillTitle: "나비의 서",
  eSkillDesc: "소멸하지 않는 화염만이 세상의 불결한 것을 정화할 수 있다. <br />호두는 일정량의 HP를 소모해 주변의 적을 넉백시키고 피안접무 상태로 들어간다.",
  eSkillEffect1: "피안접무",
  eSkillEffect1Text1: "상태 진입 시 호두의 HP 최대치에 기반하여 공격력이 증가한다. 이러한 공격력 증가는 호두 기초 공격력의 400%를 넘을 수 없다.",
  eSkillEffect1Text2: "공격이 <strong class='pyro'>불 원소 피해</strong>로 전환되며, 해당 원소 전환은 다른 원소 부여 효과로 대체될 수 없다.",
  eSkillEffect1Text3: "강공격은 명중된 적에게 혈매향 효과를 부여한다.",
  eSkillEffect1Text4: "호두의 경직 저항력이 상승한다.",
  eSkillEffect2: "혈매향",
  eSkillEffect2Text1: "혈매향 상태의 적은 4초마다 한 번씩 <strong class='pyro'>불 원소 피해</strong>를 받는다. 해당 피해는 원소전투 스킬 피해로 취급한다.",
  eSkillEffect2Text2: "타깃 하나당 혈매향 효과는 1개만 존재할 수 있고, 효과의 지속 시간은 호두에 의해서만 리셋된다.",
  eSkillEffectEnd: "피안접무의 효과는 지속 시간이 끝나거나, 호두가 퇴장 혹은 전투 불능 상태가 되었을 때 사라진다.",
  eSkillQuote: "호두가 배운 비법 창술, 그 가르침의 첫 구절은 이러하다: 「창으로 저승길 문을 열고, 나비를 따라 삼도천을 건너네」",
  eSkillEffectTitle1: "스킬 소모",
  eSkillEffectTitle2: "공격력 증가",
  eSkillEffectTitle3: "혈매향 피해",
  eSkillEffectTitle4: "혈매향 <br/>지속 시간",
  qSkillTitle: "평안의 서",
  qSkillDesc:
    "타오르는 유령을 휘둘러 넓은 범위의 <strong class='pyro'>불 원소 피해</strong>를 준다. <br/>적에게 명중 시 호두의 HP 최대치에 기반하여 호두의 HP를 회복한다. 해당 효과는 최대 5명의 명중된 적에게서 효과를 볼 수 있다. <br/>스킬 명중 시 호두의 HP가 50% 이하라면, 가하는 피해와 치유량이 더 높아진다.",
  qSkillQuote:
    "이미 세상을 떠난 존재가 이 세상에 버젓이 남아있다면, 왕생당은 애가 탄다. 화장(火葬)은 호두를 안심시킬 수 있는 가장 좋은 방법이다. 그녀가 초조해할수록 화력은 더욱 거세질 것이다.",
  qSkillEffectTitle1: "스킬 피해",
  qSkillEffectTitle2: "낮은 HP일 때 <br/>스킬 피해량",
  qSkillEffectTitle3: "스킬 치유량",
  qSkillEffectTitle4: "낮은 HP일 때 <br/>스킬 치유량",
  skillDuration: "지속 시간",
  skillCD: "재사용 <br/>대기시간",
  skillEnergyCost: "원소 에너지",
  currentHP: "현재 HP의 %%",
  maxHP: "HP 최대치의 %%",
  second: "초",
  passiveTitle1: "모습을 감춘 나비",
  passiveDesc1: "나비의 서가 부여하는 피안접무 상태가 끝난 후, <br />파티 내 모든 캐릭터(호두 자신은 제외)의 치명타 확률이 12% 증가한다. <br />지속 시간: 8초",
  passiveUnlock1: "캐릭터 Lv.1 돌파",
  passiveTitle2: "핏빛 분장",
  passiveDesc2: "호두의 HP가 50% 이하일 때, 33%의 <strong class='pyro'>불 원소 피해 보너스</strong>를 획득한다.",
  passiveUnlock2: "캐릭터 Lv.4 돌파",
  passiveTitle3: "다다익선",
  passiveDesc3: "요리를 완벽 조리하면 18%의 확률로 같은 종류의 「이상한」 품질의 요리를 1개 획득한다.",
  constellTitle1: "진홍의 꽃다발",
  constellDesc1: "나비의 서가 부여한 피안접무 상태일 때, 호두의 강공격은 스태미나를 소모하지 않는다.",
  constellTitle2: "비처럼 내리는 불안",
  constellDesc2: "혈매향이 가하는 피해가 증가한다. 피해 증가량은 효과 부여 시, 호두 HP 최대치의 10%만큼 증가한다. <br />또한 평안의 서는 명중 시 적에게 혈매향 효과를 부여한다.",
  constellTitle3: "적색 피의 의식",
  constellDesc3: "나비의 서의 스킬 레벨+3 최대 Lv.15까지 상승",
  constellTitle4: "영원한 안식의 정원",
  constellDesc4: "호두가 부여한 혈매향 효과 상태의 적 처치 시, 주변의 파티 내 캐릭터(호두 자신은 제외)의 치명타 확률이 12% 증가한다. <br />지속시간: 15초",
  constellTitle5: "꽃잎 향초의 기도",
  constellDesc5: "평안의 서의 스킬 레벨+3 최대 Lv.15까지 상승",
  constellTitle6: "나비 잔향",
  constellDesc6:
    "호두의 HP가 25% 이하로 떨어지거나 전투 불능이 될 정도의 피해를 입으면 아래 효과가 발동한다: <br />호두는 해당 피해로 전투 불능 상태에 빠지지 않고 다음 10초 동안 호두의 모든 원소 내성과 물리 내성이 200% 증가한다. <br />동시에 치명타 확률이 100% 증가하고, 경직 저항력이 대폭 상승한다. <br />해당 효과는 호두의 HP가 1일 때 자동으로 발동된다. <br />해당 효과는 60초마다 1번 발동한다."
};
