import r4_sword_favonius_sword from "assets/weapons/r4_sword_favonius_sword.png";
import r4_claymore_favonius_greatsword from "assets/weapons/r4_claymore_favonius_greatsword.png";
import r4_polearm_favonius_lance from "assets/weapons/r4_polearm_favonius_lance.png";
import r4_bow_favonius_warbow from "assets/weapons/r4_bow_favonius_warbow.png";
import r4_catalyst_favonius_codex from "assets/weapons/r4_catalyst_favonius_codex.png";

const common = {
  sword: r4_sword_favonius_sword,
  claymore: r4_claymore_favonius_greatsword,
  polearm: r4_polearm_favonius_lance,
  bow: r4_bow_favonius_warbow,
  catalyst: r4_catalyst_favonius_codex
};

export default common;
